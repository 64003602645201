/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Montserrat Regular";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat SemiBold";
  src: url("./assets/fonts//Montserrat-SemiBold.ttf") format("truetype");
}

@layer components {

    .navigation {
        @apply
        font-montserrat-medium text-xs py-7
        sm:text-sm
        md:text-base md:py-8
        lg:text-lg md:py-9
        xl:text-lg xl:py-11
        2xl:text-xl
    }

    .bttn {
        @apply
        bg-[#F5A18F] rounded-[5px] py-[6px] font-montserrat-regular

    }

    .inpt {
        @apply
        font-montserrat-regular rounded-[5px]
        placeholder:text-black border-[#A3ACA3] border-[1px] border-solid 
    }

    .btn-default {
        @apply
        py-6 px-20 rounded border-0 border-solid bg-button text-center
        sm:py-8 sm:px-6
        md:py-8 md:px-8
        lg:py-8 lg:px-10
        xl:py-8 xl:px-20
    }

    .btn-mod {
        @apply
        text-14 p-1
        md:p-2 md:text-14
        lg:p-2.5 lg:text-16
        xl:p-[10px] xl:text-18
    }

    .aside-default {
        @apply
        text-[12px] py-[3px] my-[28px] font-montserrat-medium
        sm:text-[14px] sm:my-[32px]
        md:text-[14px] md:my-[38px]
        lg:text-[16px] md:my-[40px]
        xl:text-[16px] xl:py-[3px] xl:my-[44px]
    }


    .header {
        @apply
          flex flex-col bg-none text-white bg-white
          sm:text-12 sm:bg-cover sm:bg-top sm:bg-header-background
          md:text-14 lg:text-16 xl:text-16
    }

    .header__top {
        @apply
            /* flex flex-row */ items-center justify-between border-solid border-b-[1px] h-auto
            font-montserrat-regular p-[20px]
            sm:pt-[30px] sm:pb-[20px] sm:px-[40px]
            md:pt-[30px] md:pb-[20px] md:px-[40px]
            lg:pt-[30px] lg:pb-[20px] lg:px-[40px]
            xl:pt-[30px] xl:pb-[20px] xl:px-[40px]
    }

    .header__middle {
        @apply
            flex flex-row items-center justify-around hidden h-auto 
            font-montserrat-medium text-[16px]
            border-b-[1px] border-solid border-white
            sm:flex sm:py-7 sm:px-16
            md:px-32
            lg:px-36
            xl:px-40
    }

    .header__bottom {
        @apply
            flex flex-col items-center justify-center py-[90px]
            bg-header-background bg-no-repeat bg-cover h-[250px] text-center  
            sm:h-[900px] sm:bg-none sm:pt-[268px] sm:pb-[410px]
    }

    .search {
        @apply
            flex flex-row items-center  mr-[18px] w-[80px] w-full text-black border-black rounded-lg border sm:border-white
            sm:w-[140px] sm:mr-[24px]
            md:w-[140px] md:mr-[26px]
            lg:w-[160px] lg:mr-[28px]
            xl:w-[160px] xl:mr-[30px]
    }

    .citySelect {
        @apply
            w-full text-black bg-[#f7f7f7] border rounded-md shadow-sm
            focus:outline-none focus:ring focus:ring-blue-500 
            sm:w-[100px] sm:text-white sm:bg-transparent sm:border-gray-300
    }

    .citySelect__ul {
        @apply
            p-[6px]
            text-black bg-[#f7f7f7] border rounded-md shadow-sm
            focus:outline-none focus:ring focus:ring-blue-500 
            sm:text-white sm:bg-transparent sm:border-gray-300 sm:rounded-t-none
            sm:w-[100px]
    }

    .citySelect__li {
        @apply
            cursor-pointer
    }

    .sortSelect {
        @apply
            border-[#A3ACA3] border-[1px] border-solid rounded-[5px]
            w-full p-[6px] mb-[10px] text-left text-black shadow-sm
            focus:outline-none focus:ring focus:ring-blue-500 
            sm:w-[240px] sm:mb-[20px] sm:bg-transparent 
    }

    .sortSelect__ul {
        @apply
            min-w-[210px] p-[6px]
            text-black bg-[#f7f7f7] 
            sm:text-white sm:bg-transparent sm:border sm:border-gray-300 sm:rounded-t-none sm:rounded-md sm:w-[100px]
    }

    .sortSelect__li {
        @apply
            w-full
    }

    .h2__home {
        @apply
            text-[18px] py-[2px] font-montserrat-medium
            sm:text-[22px] sm:pb-[4px]
            md:text-[24px] md:pb-[6px]
            lg:text-[28px] lg:pb-[6px]
            xl:text-[32px] xl:pb-[6px]
    }

    .h2__aside {
        @apply
            text-[14px] py-[2px] font-montserrat-semibold
            sm:text-[22px] sm:p-[0px] sm:font-montserrat-medium
            md:text-[24px]
            lg:text-[28px]
            xl:text-[32px]

    }

    .h3__checkout {
        @apply
            text-[14px] py-[2px] font-montserrat-semibold
            sm:text-[20px] sm:p-[0px] sm:font-montserrat-medium
            xl:text-[24px]
    }

    .h-default {
        @apply
            text-[18px] font-montserrat-medium
            sm:text-[24px]
            md:text-[30px]
            lg:text-[36px]
            xl:text-[42px]
    }

    .a-home {
        @apply
            text-[12px] font-montserrat-semibold
            sm:text-[12px] 
            md:text-[14px]
            lg:text-[16px]
            xl:text-[16px]
    }

    .footer {
        @apply
        text-[12px] pb-[40px] pt-[35px]  px-[95px] font-montserrat-regular box-border bg-[#807979]
        sm:pb-[44px] sm:pt-[44px] sm:px-[38px] sm:block 
        md:pb-[46px] md:pt-[46px] md:px-[38px]
        lg:pb-[46px] lg:pt-[46px] lg:px-[40px]
        xl:pb-[50px] xl:pt-[50px] xl:px-[40px]
    }

    .filter-goods {
        @apply
            hidden
            sm:flex sm:flex-col sm:gap-[10px]
            sm:px-[16px] sm:pb-[40px] sm:pt-[36px]
            sm:rounded-[5px] sm:border-[#A3ACA3] sm:border-[1px] sm:border-solid 
            sm:font-montserrat-regular 
            sm:text-[12px]
            md:text-[14px]
            lg:text-[16px]
            xl:text-[18px]
    }

    .filter-goods__group {
        @apply
        flex flex-col gap-[10px] mt-[26px]
    }

    .border-default {
        @apply
            border-2 border-[#e3e6e3] rounded border-solid
    }

    .border-mod {
        @apply
        border-[1px] border-[#a0a0a0] rounded border-solid
    }

    .text-default {
        @apply
        text-[14px]
        sm:text-[16px]
    }

    .padding-default {
        @apply
            px-[20px]
            sm:px-[22px]
            md:px-[24px]
            lg:px-[28px]
            xl:px-[30px]
    }

    .catalog__text {
        @apply
            text-[14px] font-montserrat-medium
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            xl:text-[24px]
    }

    .carusel__arrow {
        @apply
        w-[14px] h-[14px]
        sm:w-[23px] sm:h-[23px]
        md:w-[32px] md:h-[32px]
        lg:w-[41px] lg:h-[41px]
        xl:w-[50px] xl:h-[50px]
    }

    .card {
        @apply
            flex flex-col pb-[22px] pt-[18px] px-[8px] font-montserrat-regular justify-between
            text-[14px]
            md:text-[16px] md:px-[20px] md:pt-[16px] md:pb-[26px] md:gap-[30px]
            xl:text-[18px]
    }

    .card__img {
        @apply
        h-[130px] w-[140px]
        sm:w-[190px]
        md:w-[240px]
        lg:w-[280px]
        xl:h-[284px] xl:w-[320px]

    }

    .card__price {
        @apply
            font-montserrat-regular text-[12px] py-[2px]
            sm:text-[14px] sm:font-montserrat-semibold 
            md:text-[16px]
            lg:text-[18px]
            xl:text-[24px] py-[4px]
    }

    .card__stock {
        @apply
            font-montserrat-regular text-[8px] text-[#000000]
            sm:text-[8px]
            md:text-[10px]
            lg:text-[12px]
    }

    .card-btn {
        @apply
        rounded-full w-[10px] h-[10px]
        sm:w-[12px] sm:h-[12px]
        md:w-[16px] md:h-[16px]
        lg:w-[20px] lg:h-[20px]
        xl:w-[22px] xl:h-[22px]
    }

    .card-page {
        @apply
        flex flex-col gap-[25px] p-[20px] w-[100%] h-auto
        sm:w-[48%] sm:px-[20px] sm:pt-[30px] sm:pb-[45px]
    }

    .popup__content {
       @apply
       sm:w-auto
       md:w-[80%]
       lg:w-[80%]
       xl:w-[810px] xl:h-[610px]
    }

    .style-filter {
        @apply
        flex flex-row flex-wrap justify-start gap-y-[10px] gap-x-[20px] pt-[30px] pb-[20px]
        sm:justify-start sm:gap-[20px] sm:pb-[30px] sm:pt-[40px]
    }

    .style-filter__bttn {
        @apply
        border-[#A3ACA3] border-[1px] border-solid rounded-[5px]
        px-[6px] py-[6px]
    }

    .style-filter__bttn-clicked {
        @apply
        bg-black text-white
        border-[#A3ACA3] border-[1px] border-solid rounded-[5px]
        px-[10px] py-[6px]
        sm:p-[10px]
    }

    .product-image-slider {

    }


    /* Delete triangle in <details> */
    details summary::-webkit-details-marker {
        display: none;
    }

    details summary {
        list-style: none;
    }
}
